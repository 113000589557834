module.exports = {
  onRouteUpdate: ({ location, prevLocation }) => {
    if (typeof window === "undefined" || typeof window.gtag !== `function`) {
      return null
    }

    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined
    window.gtag(`event`, `page_view`, { page_path: pagePath })

    return null
  },
}
